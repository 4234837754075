import * as types from './uiOnboardingActionTypes';

export function markSplashAsSeen(): Record<string, any> {
  return {
    type: types.MARK_SPLASH_AS_SEEN,
  };
}
export function completeOnboardingOnDevice(): Record<string, any> {
  return {
    type: types.COMPLETE_ONBOARDING_ON_DEVICE,
  };
}
export function resetOnboardingOnDevice(): Record<string, any> {
  return {
    type: types.RESET_ONBOARDING_ON_DEVICE,
  };
}
export function markTimezoneWarningAsShown(): Record<string, any> {
  return {
    type: types.MARK_TIMEZONE_WARNING_AS_SHOWN,
  };
}
