/* eslint-disable react/display-name */
import { memo, useEffect, useState } from 'react';
import type { Node } from 'react';

import { GlobalHotKeys } from 'react-hotkeys';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import EventBranding from 'src/shared/app/nav/component/branding/event/EventBranding';
import { markSplashAsSeen } from 'src/shared/app/onboarding/action/uiOnboardingActions';
import messages from 'src/shared/app/onboarding/l10n/splashL10n';
import { getEventShowcasePageUrl } from 'src/shared/domain/event/selector/eventSelectors';

import styles from './SplashScreen.style';
import SplashSponsorBranding from './SplashSponsorBranding';

const useStyles = createUseThemeStyles(styles);

const SplashScreen = (): Node => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const showcasePageUrl = useSelector((state) =>
    getEventShowcasePageUrl(state, location),
  );
  const [isMounted, setMounted] = useState(false);
  const [isPaused, setPaused] = useState(false);

  const handleFinish = () => {
    if (showcasePageUrl) {
      window.location.href = showcasePageUrl;
    } else {
      dispatch(markSplashAsSeen());
    }
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  const onSpaceKey = () => setPaused((wasPaused) => !wasPaused);

  const keyMap = {
    PAUSE_RESUME: 'space',
  };
  const handlers = {
    PAUSE_RESUME: onSpaceKey,
  };
  const help = isMounted && (
    <div className={classes.helpCtn}>
      <p className={classes.help} aria-live="assertive">
        {intl.formatMessage(messages[isPaused ? 'resume' : 'pause'], {
          key: keyMap.PAUSE_RESUME.toUpperCase(),
          kbd: (str) => <kbd key={str}>{str}</kbd>,
        })}
      </p>
    </div>
  );
  return (
    <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
      <div className={classes.root}>
        {help}
        <div className={classes.top}>
          <EventBranding
            classes={{
              logo: classes.logo,
            }}
          />
        </div>
        <div className={classes.bottom}>
          <SplashSponsorBranding handleLoad={handleFinish} abort={isPaused} />
        </div>
      </div>
    </GlobalHotKeys>
  );
};

export default memo(SplashScreen);
