import { memo, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import {
  getEventPlugins,
  getEventSplashSponsors,
  getEventSponsorLogo,
  getEventSponsorLogoAltText,
  getEventSponsorPluginSplashTitle,
} from '@braindate/domain/lib/event/util';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import { sponsorshipPlugin as sponsorshipPluginKey } from 'src/shared/app/feature/settings/featureSettings';
import { splashMinAppearance } from 'src/shared/app/onboarding/setting/splashSettings';
import useEvent from 'src/shared/domain/event/hook/useEvent';
import ResponsivePicture from 'src/shared/ui/component/image/ResponsivePicture';

import styles from './SplashSponsorBranding.style';

type Props = {
  handleLoad: () => void;
  abort?: boolean;
};
const useStyles = createUseThemeStyles(styles);

const SplashSponsorBranding = ({ abort, handleLoad }: Props) => {
  const intl = useIntl();
  const imgWidth = 300;
  const { locale } = intl;
  const event = useEvent();
  const [sponsor] = getEventSplashSponsors(event);
  const sponsorshipPlugin = getEventPlugins(event)[sponsorshipPluginKey];
  const sponsorsSplashTitle =
    sponsorshipPlugin &&
    getEventSponsorPluginSplashTitle(sponsorshipPlugin, locale);
  const logo = sponsor && getEventSponsorLogo(sponsor);
  const logoAltText = sponsor && getEventSponsorLogoAltText(sponsor, locale);

  const setTiming = () => {
    const timeoutId = setTimeout(handleLoad, splashMinAppearance);
    setTimeoutId(timeoutId);
  };

  const abortTiming = () => {
    clearTimeout(timeoutId);
    setTimeoutId(null);
  };

  const [isImgLoading, setImgLoading] = useState(true);
  const [timeoutId, setTimeoutId] = useState(null);
  useEffect(() => {
    if (abort && timeoutId) {
      abortTiming();
    } else if (!abort && !isImgLoading) {
      setTiming();
    }
  }, [abort]);

  const onImgLoad = () => {
    setTiming();
    setImgLoading(false);
  };

  const classes = useStyles({
    imgWidth,
  });
  const textElt = <span>{sponsorsSplashTitle}</span>;
  return (
    <>
      {textElt}
      <ResponsivePicture
        className={classes.root}
        picture={logo}
        alt={logoAltText}
        displayWidth={imgWidth}
        handleLoad={onImgLoad}
      />
    </>
  );
};

export default memo<Props>(SplashSponsorBranding);
